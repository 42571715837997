<template>
    <div class="v-start">
        <header class="head">
            <img :src="logo" alt="logo">
        </header>
        <div class="top">
            <div class="doc-circle">
                <img src="@/assets/assignment.svg" alt="right-arrow">
            </div>
            <div class="offer-btn" @click="$router.push(`/contract/${params.rid}/${params.key}`)">
                <span>view offer</span>
                <img src="@/assets/right-arrow.svg" alt="right-arrow">
            </div>
            <p class="descr">{{ fid68 }}</p>
        </div>
        <div class="bottom">
            <div class="container">
                <h2 class="title">{{ fid69 }}</h2>
                <p class="descr">
                    {{ fid70 }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: function() {
        return {
            fid68: "",
            fid69: "",
            fid70: "",
            logo: "",
        }
    },
    async mounted() {
        try {
            let { rid } = this.$route.params
            let response = await fetch(`https://offers-dev.genstonerealty.com/api/getFields.php?clist=67.68.69.70&rid=${rid}`)

            response = await response.json()
            
            this.logo = response.data[67].url
            this.fid68 = response.data[68]
            this.fid69 = response.data[69]
            this.fid70 = response.data[70]
        } catch (err) {
            console.error(err)
        }
    },
    computed: {
        params() {
            return this.$route.params
        },
    },
}

</script>

<style lang="sass">

div.v-start

    header.head
        min-height: 52px
        display: flex
        justify-content: center
        align-items: center
        padding: 8px 15px

        img
            max-width: 84px

    div.top
        background: #201648
        color: #fff
        padding: 20px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

    div.doc-circle
        width: 72px
        height: 72px
        display: flex
        justify-content: center
        align-items: center
        background: #508BA9
        border-radius: 50%
        margin-bottom: 20px

    div.offer-btn
        margin-bottom: 20px
        background: #F77B00
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12)
        border-radius: 3px
        min-width: 168px
        min-height: 52px
        display: flex
        justify-content: center
        align-items: center
        text-transform: uppercase
        color: #fff
        letter-spacing: 0.02em
        font-weight: 600
        text-transform: uppercase
        cursor: pointer

    div.bottom
        padding: 40px 0
        background: #fff

        div.container
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            text-align: center

        h2.title
            font-weight: 400
            font-size: 24px
            line-height: 36px
            margin-bottom: 20px

        p.descr
            padding-top: 20px
            border-top: 1px solid rgba(0, 0, 0, 0.12)
            letter-spacing: 0.01em
            font-size: 14px
            line-height: 140%

</style>